import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'

import imageCover from '../../../static/transparent.png'

import { IFluidPhoto } from '../../@types'

import './heart.css'
import styled from 'styled-components'

const LOADANIMATIONWAIT = 700

interface IProps {
  alt: string
  fluidAsset?: IFluidPhoto
  style?: object
  imgStyle?: object
  className?: string
}

export function Image({ fluidAsset, alt, style, imgStyle, className }: IProps) {
  if (!fluidAsset) {
    return null
  }

  const [loading, setLoading] = useState<boolean | null>(null)
  const loadingRef            = useRef(loading)
  const timeoutRef            = useRef<NodeJS.Timeout | number>()

  function onLoad() {
    loadingRef.current = false
    setLoading(false)
  }

  function onStartLoad() {
    timeoutRef.current = setTimeout(() => {
      if (loadingRef.current === null) {
        setLoading(true)
      }
    }, LOADANIMATIONWAIT)
  }

  useEffect(() => {
    return () => {
      if (typeof timeoutRef.current === 'number') {
        clearInterval(timeoutRef.current)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <ImageCover src={imageCover} alt='null' />
      <Img
        onLoad={onLoad}
        onStartLoad={onStartLoad}
        alt={alt}
        className={`${loading ? 'loading' : 'image'} ${className}`}
        fluid={fluidAsset}
        style={{ ...style, zIndex: -2 }}
        imgStyle={imgStyle}
      />
    </div>
  )
}

const ImageCover = styled.img`
  margin: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`

// const delay = 3000
// const delayedAsset = {
//   ...fixedAsset,
//   srcSetWebp: `http://www.deelay.me/${delay}/${fixedAsset.srcSetWebp}`,
// }
