import styled from 'styled-components'

import { cardEnterAnim } from '../../globalStyles'
import { rhythm } from '../../utils/typography'
import { mediaQueries } from '../../vars'
import { Image } from '../common/Image'

export const Container = styled.section`
  max-width: 700px;
  animation: ${cardEnterAnim} 0.6s 0.2s backwards;
  position: relative;
  background: #ffffffdd;
  padding: ${rhythm(1)};
  border-radius: 10px;
  width: fit-content;
  margin: ${rhythm(1)} auto;

  h1 {
    font-size: 2rem;
    width: fit-content;
    margin: 0 auto ${rhythm(2)};
    display: flex;
    align-items: center;

    img {
      margin-bottom: ${rhythm(1 / 3)};
      margin-left: ${rhythm(1 / 3)};
    }

    @media ${mediaQueries.general.handheld} {
      font-size: 1.3rem;
      line-height: 1.9rem;
      margin-bottom: ${rhythm(1)};
    }
  }
`

export const Portrait = styled(Image)`
  float: left;
  width: 50%;
  height: auto;
  border-radius: 30%;
  margin: 0 ${rhythm(2)} ${rhythm(2)} 0;
  box-shadow: var(--box-shadow-light);
  
  @media ${mediaQueries.general.handheld} {
    margin: 0 ${rhythm(1)} ${rhythm(.4)} 0;
  }
`
