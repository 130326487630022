import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

import personIcon from '../../../static/svg/user.svg'

import { PageTitles } from '../../vars'
import { Layout } from '../common/Layout'
import { Container, Portrait } from './styles'
import { IBlockText, IFluidPhoto } from '../../@types'

interface IProps {
  aboutPageText: IBlockText[]
  portrait: IFluidPhoto
}

export function AboutPage({ aboutPageText, portrait }: IProps) {
  return (
    <Layout currentPageTitle={PageTitles.About} darkBg={false}>
      <Container>
        <header>
          <h1>
            {PageTitles.About} <img src={personIcon} alt='People icon' />
          </h1>
        </header>

        <Portrait
          fluidAsset={portrait}
          alt='Portrait of Larry Hilderman'
        />

        <BlockContent blocks={aboutPageText} />
      </Container>
    </Layout>
  )
}
