import React from 'react'
import { graphql } from 'gatsby'

import { IFluidPhoto, ISiteCopy } from '../@types'
import { AboutPage } from '../components/AboutPage'

interface IProps {
  data: {
    sanitySiteCopy: Pick<ISiteCopy, '_rawAboutPageText'>
    sanityPortrait: { portrait: { asset: { fluid: IFluidPhoto } } }
  }
}

export default function index({ data }: IProps) {
  return <AboutPage
    aboutPageText={data.sanitySiteCopy._rawAboutPageText}
    portrait={data.sanityPortrait.portrait.asset.fluid}
  />
}

export const query = graphql`
    query {
        sanitySiteCopy {
            _rawAboutPageText
        }
        sanityPortrait {
            portrait {
                asset {
                    fluid(maxWidth: 400) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
    }
`
